import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  MPActionButton,
  MPAnimations,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { SettingsSendableNftsQuery } from 'graphql/__generated__/SettingsSendableNftsQuery.graphql';
import {
  TransferPortalSendStatusEnum,
  TransferPortalSendTypeEnum,
} from 'types/__generated__/graphql';

import ROUTES from 'constants/Routes';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';

import * as styles from 'css/pages/transfer/TransferSection.module.css';

export enum TransferSectionType {
  LegacyCustodialTokens = 'LegacyCustodialTokens',
  SafeboxTokens = 'SafeboxTokens',
}

interface TransferSectionProps {
  onTransferStart: (
    transferType: TransferSectionType,
    sendableNfts: SettingsSendableNftsQuery['response']['sendableNfts']
  ) => void;
  sectionType: TransferSectionType;
  sendableNfts: SettingsSendableNftsQuery['response']['sendableNfts'];
}

function Header({
  sendableNfts,
  sectionType,
  onTransferStart,
}: TransferSectionProps) {
  const unsentNfts = useMemo(
    () =>
      sendableNfts.filter((sendableNft) =>
        [
          TransferPortalSendStatusEnum.Custodial,
          TransferPortalSendStatusEnum.Safebox,
        ].includes(sendableNft.status as TransferPortalSendStatusEnum)
      ),
    [sendableNfts]
  );

  const handleClick = useCallback(
    () => onTransferStart(sectionType, unsentNfts),
    [sectionType, unsentNfts, onTransferStart]
  );

  return (
    <div
      className={joinClasses(
        CSSGlobal.Flex.ColCenterAlign,
        CSSGlobal.Flex.Centered,
        CSSGlobal.Cursor.Default,
        CSSGap[16],
        styles.header
      )}
    >
      <div
        className={joinClasses(
          MPFonts.headline3,
          CSSGlobal.Flex.InlineRowCenterAlign
        )}
      >
        {sectionType === TransferSectionType.SafeboxTokens
          ? 'Safebox'
          : 'Custodial Wallet'}{' '}
        Tokens
      </div>
      {unsentNfts.length ? (
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            CSSGlobal.Flex.Col,
            CSSGap[10],
            MPFonts.textSmallMedium,
            styles.description
          )}
        >
          {`Batch transfers all tokens currently stored in ${
            sectionType === TransferSectionType.SafeboxTokens
              ? 'Safebox Wallet'
              : 'a Custodial Wallet'
          }  to a personal Wallet of your choice or choose individually where to transfer each token.`}
          <MPActionButton size="large" variant="primary" onClick={handleClick}>
            {`Transfer All ${
              sectionType === TransferSectionType.SafeboxTokens
                ? 'Safebox'
                : 'Custodial Wallet'
            } Tokens`}
          </MPActionButton>
        </div>
      ) : !unsentNfts.length && sendableNfts.length ? (
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.headline4,
            MPColorClass.GoldMain
          )}
        >
          All tokens have been successfully transferred to a personal wallet.
        </div>
      ) : null}
    </div>
  );
}

function ListItem({
  sendableNft,
  className,
  sectionType,
  onTransferStart,
}: {
  className: string;
  onTransferStart: TransferSectionProps['onTransferStart'];
  sectionType: TransferSectionType;
  sendableNft: TransferSectionProps['sendableNfts'][number];
}) {
  const isMobile = useIsMobile();
  const font = isMobile ? MPFonts.textSmallMedium : MPFonts.textNormalMedium;

  const artwork = useMemo(() => sendableNft.nft, [sendableNft]);

  const handleClick = useCallback(
    () => onTransferStart(sectionType, [sendableNft]),
    [sectionType, sendableNft, onTransferStart]
  );

  return (
    <div className={joinClasses(styles.listItemContainer, className)}>
      <div
        className={joinClasses(
          styles.listItemContent,
          styles.listItemContentLayout
        )}
      >
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${artwork.metadata.standardImage})` }}
        />
        <div className={joinClasses(font, styles.title)}>
          <Link
            to={ROUTES.NFT(artwork.listing.productSlug)}
            className={joinClasses(
              'invisibleAnchor',
              'ellipsis',
              CSSGlobal.Cursor.Pointer,
              MPAnimations.Color.DarkToLight
            )}
            reloadDocument
          >
            {artwork.metadata.title}
          </Link>
        </div>
        <div className={joinClasses(font, styles.artist)}>
          <span className="ellipsis">
            {isMobile ? (
              <span className={MPColorClass.SolidNeutralGray5}>by&nbsp;</span>
            ) : null}
            {generateFormattedUserFullName(artwork.metadata.author.fullName)}
          </span>
        </div>
        <div className={joinClasses(font, styles.wallet)}>
          {sendableNft.status === TransferPortalSendStatusEnum.Safebox ||
          (sendableNft.status === TransferPortalSendStatusEnum.Pending &&
            sendableNft.type === TransferPortalSendTypeEnum.Safebox)
            ? 'Safebox'
            : sendableNft.status === TransferPortalSendStatusEnum.Custodial ||
              (sendableNft.status === TransferPortalSendStatusEnum.Pending &&
                sendableNft.type === TransferPortalSendTypeEnum.Custodial)
            ? 'Custodial Wallet'
            : sendableNft.status === TransferPortalSendStatusEnum.Personal ||
              sendableNft.status === TransferPortalSendStatusEnum.Sent
            ? 'Personal Wallet'
            : null}
        </div>
        <div className={joinClasses(font, styles.action)}>
          {sendableNft.status ===
          TransferPortalSendStatusEnum.Personal ? null : sendableNft.status ===
            TransferPortalSendStatusEnum.Pending ? (
            <span
              className={joinClasses(
                MPFonts.textSmallSemiBold,
                MPColorClass.SolidNeutralGray3
              )}
            >
              Transferring...
            </span>
          ) : sendableNft.status === TransferPortalSendStatusEnum.Sent ? (
            <span
              className={joinClasses(
                MPFonts.textSmallSemiBold,
                MPColorClass.GoldMain
              )}
            >
              Transfer Complete
            </span>
          ) : (
            <MPActionButton
              size="small"
              variant="secondary"
              onClick={handleClick}
            >
              Transfer
            </MPActionButton>
          )}
        </div>
      </div>
    </div>
  );
}

function List({
  sendableNfts,
  sectionType,
  onTransferStart,
}: {
  onTransferStart: TransferSectionProps['onTransferStart'];
  sectionType: TransferSectionType;
  sendableNfts: TransferSectionProps['sendableNfts'];
}) {
  const isMobile = useIsMobile();

  return sendableNfts.length ? (
    <div className={styles.list}>
      {!isMobile && (
        <div
          className={joinClasses(
            CSSGlobal.Cursor.Default,
            styles.listItemContentHeader,
            styles.listItemContentLayout
          )}
        >
          <div className={joinClasses(MPFonts.textSmallMedium, styles.title)}>
            Artwork
          </div>
          <div className={joinClasses(MPFonts.textSmallMedium, styles.artist)}>
            Artist
          </div>
          <div className={joinClasses(MPFonts.textSmallMedium, styles.wallet)}>
            Current Wallet
          </div>
          <div
            className={joinClasses(MPFonts.textSmallMedium, styles.action)}
          />
        </div>
      )}

      {sendableNfts.map((sendableNft) => (
        <ListItem
          key={sendableNft.nft.id}
          sendableNft={sendableNft}
          className={joinClasses(styles.item)}
          sectionType={sectionType}
          onTransferStart={onTransferStart}
        />
      ))}
    </div>
  ) : (
    <div
      className={joinClasses(
        MPFonts.textLargeRegular,
        MPColorClass.SolidNeutralGray5,
        CSSGlobal.Cursor.Default,
        CSSGlobal.Flex.CenteredCol
      )}
    >
      No&nbsp;results&nbsp;found.
    </div>
  );
}

function TransferSection({
  sendableNfts,
  sectionType,
  onTransferStart,
}: TransferSectionProps) {
  return (
    <section>
      <Header
        sendableNfts={sendableNfts}
        sectionType={sectionType}
        onTransferStart={onTransferStart}
      />

      <List
        sendableNfts={sendableNfts}
        sectionType={sectionType}
        onTransferStart={onTransferStart}
      />
    </section>
  );
}

export default withDefaultErrorBoundary(TransferSection, {
  hideState: true,
  suspenseless: true,
});
