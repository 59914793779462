import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import { joinClasses } from '@mp-frontend/core-utils';

import SettingsSendableNftsQueryType, {
  SettingsSendableNftsQuery,
} from 'graphql/__generated__/SettingsSendableNftsQuery.graphql';
import {
  TransferPortalSendStatusEnum,
  TransferPortalSendTypeEnum,
} from 'types/__generated__/graphql';

import ROUTES from 'constants/Routes';
import { APP_NAME } from 'constants/Utils';
import useSession from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import setDocTitle from 'utils/setDocTitle';

import TransferDialog, { TransferDialogProps } from './TransferDialog';
import TransferSection, { TransferSectionType } from './TransferSection';

import * as styles from 'css/pages/transfer/TransferPage.module.css';

const TITLE = 'Transfer Portal';

const TransferSections = withLoadQuery(
  ({
    sendableNftsQuery,
  }: {
    sendableNftsQuery: WithLoadQueryProps<SettingsSendableNftsQuery>;
  }) => {
    const sendableNftsResult = usePreloadedQuery<SettingsSendableNftsQuery>(
      SettingsSendableNftsQueryType,
      sendableNftsQuery.queryRef
    );
    const safeboxNfts = useMemo(
      () =>
        sendableNftsResult.sendableNfts.filter(
          (nft) => nft.type === TransferPortalSendTypeEnum.Safebox
        ),
      [sendableNftsResult]
    );
    const custodialNfts = useMemo(
      () =>
        sendableNftsResult.sendableNfts.filter(
          (nft) => nft.type === TransferPortalSendTypeEnum.Custodial
        ),
      [sendableNftsResult]
    );

    const [transferDialogState, setTransferDialogState] = useState<Omit<
      TransferDialogProps,
      'onClose' | 'onSuccess'
    > | null>(null);

    const handleTransferStart = useCallback(
      (
        transferType: TransferSectionType,
        sendableNfts: SettingsSendableNftsQuery['response']['sendableNfts']
      ) =>
        setTransferDialogState({
          nftIds: sendableNfts
            .filter(
              ({ status }) =>
                ![
                  TransferPortalSendStatusEnum.Pending,
                  TransferPortalSendStatusEnum.Sent,
                  TransferPortalSendStatusEnum.Personal,
                ].includes(status as TransferPortalSendStatusEnum)
            )
            .map(({ nft }) => parseInt(nft.pk, 10)),
          transferType,
        }),
      []
    );

    const handleTransferClose = useCallback(
      () => setTransferDialogState(null),
      []
    );
    const handleTransferSuccess = useCallback(() => {
      sendableNftsQuery.invalidate();
      handleTransferClose();
    }, [sendableNftsQuery, handleTransferClose]);

    return (
      <>
        <TransferSection
          sendableNfts={safeboxNfts}
          sectionType={TransferSectionType.SafeboxTokens}
          onTransferStart={handleTransferStart}
        />

        <TransferSection
          sendableNfts={custodialNfts}
          sectionType={TransferSectionType.LegacyCustodialTokens}
          onTransferStart={handleTransferStart}
        />

        {!!transferDialogState && (
          <TransferDialog
            {...transferDialogState}
            onClose={handleTransferClose}
            onSuccess={handleTransferSuccess}
          />
        )}
      </>
    );
  },
  {
    sendableNftsQuery: {
      concreteRequest: SettingsSendableNftsQueryType,
    },
  }
);

function TransferPage() {
  const showTransferPortal = useGateValue('gate_transfer_portal');

  const session = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    setDocTitle([TITLE, APP_NAME].join(' | '));

    if (!session.isLoggedIn() || !showTransferPortal) {
      navigate(ROUTES.HOME());
    }
  }, [session, showTransferPortal, navigate]);

  return (
    <div className={joinClasses(CSSGlobal.Cursor.Default, styles.transferPage)}>
      <div className={styles.content}>
        <TransferSections sendableNftsQuery={{ variables: {} }} />
      </div>
    </div>
  );
}

export default withDefaultErrorBoundary(TransferPage, { hideState: true });
